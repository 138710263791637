


























































import { defineComponent } from '@vue/composition-api'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'VAdvancementDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('DocumentGenerate', {
      percentage: 'percentage',
      idSessionDocuments: 'idSessionDocuments',
      idTrainingSessionDocumentsGenerated: 'idTrainingSession',
    }),
  },
})
