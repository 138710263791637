
















































































































import { Company } from '@/api/interfaces/company'
import { computed, defineComponent, provide } from '@vue/composition-api'
import VDocumentItem from '@/components/molecules/downloadDocumentDialog/v-document-item.vue'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { Trainee } from '@/api/interfaces/trainee'
import { LogisticSession } from '@/api/interfaces/logisticSession'
import VAdvancementDialog from '@/components/molecules/downloadDocumentDialog/v-advancement-dialog.vue'
import VDialogDocumentTrainingSessionActions from '@/components/molecules/downloadDocumentDialog/v-dialog-document-training-session-actions.vue'
import { Brand } from '@/api/enums/brand'

export default defineComponent({
  setup (props) {
    const apiUrl = computed(() => process.env.NODE_ENV === 'development' ? `${process.env.VUE_APP_BACKEND_BASE_URL}` : '/')
    provide('getApiUrl', apiUrl)
    provide('getSession', props.session)
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    isSessionLogistic: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    session: {
      type: Object as () => TrainingSession | LogisticSession,
      required: true,
    },
  },
  components: {
    VDocumentItem,
    VAdvancementDialog,
    VDialogDocumentTrainingSessionActions,
  },
  data () {
    return {
      tab: null,
      traineeDocumentHeader: [
        {
          text: this.$t('form.firstName'),
          value: 'firstName',
        },
        {
          text: this.$t('form.lastName'),
          value: 'lastName',
        },
        {
          text: this.$t('form.actions'),
          value: 'actions',
        },
      ],
      openAdvancementDialog: false,
    }
  },
  computed: {
    tabs (): Array<unknown> {
      if (this.isSessionLogistic) {
        return [
          this.$t('training_session.document.flyleaf'),
          this.$t('training_session.document.preperation'),
        ]
      }
      return [
        this.$t('training_session.document.attestation'),
        this.$t('training_session.document.convocation'),
        this.$t('training_session.document.convention'),
        this.$t('training_session.document.certificate_completion'),
        this.$t('training_session.document.emargement'),
        this.$t('training_session.document.evaluation'),
      ]
    },
    traineeList (): Array<Trainee> {
      const session = (this.session as TrainingSession)
      if (session && session.needTrainings.length > 0) {
        const trainee = session.needTrainings
        return trainee
          .map((x: NeedTraining) => x.trainee as Trainee)
      }
      return []
    },
    companyList (): Array<Company> {
      const session = (this.session as TrainingSession)
      if (session && session.needTrainings.length > 0) {
        const trainee = session.needTrainings
        return trainee
          .map((x: NeedTraining) => x.company as Company)
          .reduce(function (acc: any[], x: any) {
            if (x && (!acc.find(y => y.id === x.id) || acc.length === 0)) {
              return [...acc, x]
            }
            return [...acc]
          }, [])
      }
      return []
    },
    convocationHybridAcademyBrandList (): Array<{name: string, type: string, value: Brand}> {
      const session = (this.session as TrainingSession)
      if (session && session.needTrainings.length > 0) {
        const needTrainings = session.needTrainings
        return needTrainings
          .filter((x: NeedTraining) => x.originDriverProgram === Brand.TOYOTA || x.originDriverProgram === Brand.LEXUS)
          .map((x: NeedTraining) => {
            return { name: 'Marque ' + x.originDriverProgram, type: 'brand', value: x.originDriverProgram }
          })
          .reduce(function (acc: any[], x: any) {
            if (x && (!acc.find(y => y === x) || acc.length === 0)) {
              return [...acc, x]
            }
            return [...acc]
          }, [])
      }
      return []
    },
    convocationHybridAcademyCompanyList (): Array<Company> {
      const session = (this.session as TrainingSession)
      if (session && session.needTrainings.length > 0) {
        const trainee = session.needTrainings
        return trainee
          .filter((x: NeedTraining) => x.originDriverProgram !== Brand.TOYOTA && x.originDriverProgram !== Brand.LEXUS)
          .map((x: NeedTraining) => {
            return { ...x.company, name: 'Société ' + x.company.name, type: 'company', value: x.company.name }
          })
          .reduce(function (acc: any[], x: any) {
            if (x && (!acc.find(y => y.id === x.id) || acc.length === 0)) {
              return [...acc, x]
            }
            return [...acc]
          }, [])
      }
      return []
    },
    companyAndBrandList (): Array<any> {
      return [...this.convocationHybridAcademyCompanyList, ...this.convocationHybridAcademyBrandList]
    },
  },
})
