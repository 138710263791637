































import { defineComponent } from '@vue/composition-api'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'CancelUpdateDialog',
  props: {
    saving: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('navigation', {
      open: 'cancelSaveDialog',
    }),
  },
})
