
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { FileEntity } from '@/api/interfaces/file'

export default defineComponent({
  name: 'AttachedDocuments',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      attachedDocument: null,
      loadingDownloadAttachedDocuments: [] as Array<number>,
      headers: [
        {
          text: this.$t('send_session_document.attached_document'),
          sortable: false,
          value: 'fileName',
        },
        {
          text: this.$t('send_session_document.actions'),
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    ...mapActions('file', {
      uploadFile: 'upload',
      downloadFile: 'download',
    }),
    async addAttachedDocument () {
      if (this.attachedDocument) {
        const document = await this.uploadFile(this.attachedDocument)
        this.value.push(document)
        this.attachedDocument = null
      }
    },
    async downloadAttachedDocument (document: FileEntity) {
      if (!this.loadingDownloadAttachedDocuments.includes(document.id as number)) {
        this.loadingDownloadAttachedDocuments.push(document.id as number)
        await this.downloadFile(document)
        this.loadingDownloadAttachedDocuments.splice(this.loadingDownloadAttachedDocuments.indexOf(document.id as number), 1)
      }
    },
  },
  computed: {
    ...mapState('file', {
      loadingAttachedDocument: 'loading',
    }),
  },
})
