









































import { defineComponent } from '@vue/composition-api'
import { TeachingTool } from '@/api/interfaces/teachingtool'
import { RawLocation } from 'vue-router'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'teachingToolsTableList',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    idLogisticSession: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      teachingToolsTableHeaders: [
        { text: 'Référence', value: 'reference', align: 'center', sortable: false },
        { text: 'Nom', value: 'name', align: 'center', sortable: false },
        { text: 'Action', value: 'actions', align: 'center', sortable: false },
      ],
      teachingTools: this.value,
    }
  },
  methods: {
    ...mapMutations('navigation', {
      forceCancelSaveNextRoute: 'forceCancelSaveNextRoute',
    }),
    associateTeachingTool () {
      const route: RawLocation = {
        name: 'TeachingTool List Associate LogisticSession',
        params: { idLogisticSession: this.idLogisticSession },
      }
      this.forceCancelSaveNextRoute(route)
      this.$router.push(route)
    },
    removeTeachingTool (teachingTool: TeachingTool) {
      (this.teachingTools as Array<TeachingTool>).splice(
        (this.teachingTools as Array<TeachingTool>).indexOf(teachingTool),
        1,
      )
    },
  },

})
