var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-actions',{staticClass:"d-flex justify-end"},[(
      _vm.trainingSession &&
        _vm.lastTrainingSessionDocument &&
        _vm.lastTrainingSessionDocument.id
    )?_c('v-btn',{attrs:{"color":"primary","download":"","href":(_vm.apiUrl + "print/training_sessions/documents/" + (_vm.lastTrainingSessionDocument.id)),"disabled":_vm.percentage === 100}},[_vm._v(" "+_vm._s(_vm.$t('training_session.document.download_old'))+" "),(
        _vm.lastTrainingSessionDocument &&
          _vm.lastTrainingSessionDocument.createdAt
      )?_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('training_session.document.download_old_date'))+" "+_vm._s(new Date(_vm.lastTrainingSessionDocument.createdAt).toLocaleString( 'fr-FR', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', } ))+" ")]):_vm._e()]):_vm._e(),(_vm.trainingSession.needTrainings.length > 0)?[((!_vm.percentage || _vm.percentage !== 100 || _vm.idTrainingSessionDocumentsGenerated !== _vm.trainingSession.id))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.startGenerateDocument()}}},[_vm._v(" "+_vm._s(_vm.$t('training_session.document.generate_attestation'))+" ")]):_c('v-btn',{attrs:{"color":"primary","download":"","href":(_vm.apiUrl + "print/training_sessions/documents/" + _vm.idSessionDocuments)}},[_vm._v(" "+_vm._s(_vm.$t('training_session.document.download_attestation'))+" ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }