


































































































import { defineComponent } from '@vue/composition-api'
import { mapActions } from 'vuex'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { EmailSessionDocument } from '@/api/interfaces/emailsessiondocument'
import { NeedTraining } from '@/api/interfaces/needtraining'

export default defineComponent({
  name: 'SendSessionDocumentToAllTraineesDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    titleDocumentToSend: {
      type: String,
      required: true,
    },
    typeDocument: {
      type: String,
      required: true,
    },
    logisticSession: {
      type: Object,
      required: true,
    },
    trainingSession: {
      type: Object,
      required: true,
    },
    emailSessionDocuments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SendSessionDocumentPerCompanyDialog: () => import('@/components/molecules/dialog/sendSessionDocumentPerCompany.vue'),
  },
  data: () => ({
    dialogDocumentToSend: false,
    needTrainingsChecked: [] as Array<NeedTraining>,
    needTrainingsCheckedGroupByCompany: [] as Array<Array<NeedTraining>>,
    needTrainingsOfOneCompany: [] as Array<NeedTraining>,
  }),
  computed: {
    isDocumentAttestation (): boolean {
      return this.typeDocument === 'attestation'
    },
    isDocumentConvocation (): boolean {
      return this.typeDocument === 'convocation'
    },
    needTrainingsItems (): Array<NeedTraining> {
      if (this.emailSessionDocuments === []) {
        return []
      }
      const needTrainingsWhoReceivedAnEmail: Array<any> = (this.emailSessionDocuments as Array<EmailSessionDocument>).map((x : EmailSessionDocument) => {
        if (x.typeDocument === this.typeDocument) {
          return x.needTraining
        }
      })
      const needTrainings: Array<any> = (this.trainingSession as TrainingSession).needTrainings
      return needTrainings.filter((x : NeedTraining) => {
        if (
          (this.isDocumentConvocation && x.company === null) ||
          x.trainee.email === null ||
          needTrainingsWhoReceivedAnEmail.includes(x['@id']) ||
          (this.isDocumentAttestation && x.status.code !== 'forme')
        ) {
          return false
        }

        return x
      })
    },
    headersTrainees (): Array<any> {
      return [
        {
          text: '',
          align: 'center',
          value: 'checkbox',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.trainee.lastName'),
          align: 'center',
          value: 'trainee.lastName',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.trainee.firstName'),
          align: 'center',
          value: 'trainee.firstName',
          sortable: false,
        },
        {
          text: this.$t('training_session.list.headers.trainee.company'),
          align: 'center',
          value: 'company.name',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions('emailSessionDocument', {
      saveEmailSessionDocument: 'save',
    }),
    onClose () {
      this.$emit('close')
    },
    async sendAllDocuments () {
      this.needTrainingsCheckedGroupByCompany = await this.groupByCompanies(this.needTrainingsChecked)
      this.needTrainingsOfOneCompany = this.needTrainingsCheckedGroupByCompany[0]
      this.dialogDocumentToSend = true
    },
    getNextNeedTrainingChecked () {
      const itemsRemoved = this.needTrainingsCheckedGroupByCompany.splice(0, 1) as Array<Array<NeedTraining>>
      itemsRemoved[0].forEach((needTraining: NeedTraining) => {
        this.needTrainingsChecked.splice(this.needTrainingsChecked.indexOf(needTraining), 1)
      })
      if (this.needTrainingsCheckedGroupByCompany.length > 0) {
        this.needTrainingsOfOneCompany = this.needTrainingsCheckedGroupByCompany[0]
        this.dialogDocumentToSend = true
        this.$emit('reload')
        return
      }
      this.$emit('reload')
      this.onClose()
    },
    async groupByCompanies (needTrainings: Array<NeedTraining>) {
      const array: Array<Array<NeedTraining>> = []
      await needTrainings.forEach(needTraining => {
        if (!array[needTraining.company.id]) {
          array[needTraining.company.id] = []
        }
        array[needTraining.company.id].push(needTraining)
      })

      const result: Array<Array<NeedTraining>> = []
      await array.forEach(x => {
        result.push(x)
      })

      return result
    },
    selectAllTrainees () {
      this.needTrainingsChecked = this.needTrainingsItems
    },
  },
})
