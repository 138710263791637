






































import { defineComponent } from '@vue/composition-api'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { TrainingSessionCompanyCost } from '@/api/interfaces/trainingsessioncompanycost'
import { Company } from '@/api/interfaces/company'

export default defineComponent({
  name: 'companyListWithCostsTableList',
  props: {
    trainingSession: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      companyTableHeaders: [
        { text: 'Sociétés', value: 'nameCompany', align: 'center', sortable: false },
        { text: 'Coût de la session', value: 'cost', align: 'center', sortable: false },
        { text: 'Frais supplémentaire', value: 'additionalCost', align: 'center', sortable: false },
      ],
    }
  },
  methods: {
    emitValue () {
      this.$emit('input', this.companyListWithCosts)
    },
  },
  computed: {
    companyListWithCosts (): Array<TrainingSessionCompanyCost> {
      if ((this.trainingSession as TrainingSession)?.needTrainings?.length > 0) {
        const companiesCostsActualList = (this
          .trainingSession as TrainingSession).companiesCosts as Array<
          TrainingSessionCompanyCost
          >
        const trainee = ((this.trainingSession as TrainingSession) as TrainingSession).needTrainings
        return trainee
          .map((needTraining: NeedTraining) => needTraining.company as Company)
          .reduce(function (acc: TrainingSessionCompanyCost[], x: Company) {
            if (
              x?.id &&
              (!acc.find(y => y.idCompany === x.id) || acc.length === 0)
            ) {
              const companiesCostsNewList = {} as TrainingSessionCompanyCost
              companiesCostsNewList.idCompany = x.id
              companiesCostsNewList.nameCompany = x.name
              if (companiesCostsActualList) {
                companiesCostsActualList.forEach(
                  (companyCosts: TrainingSessionCompanyCost) => {
                    if (companyCosts?.idCompany === x.id) {
                      companiesCostsNewList.cost = companyCosts.cost
                      companiesCostsNewList.additionalCost =
                        companyCosts.additionalCost
                      return false // break forEach
                    }
                  },
                )
              }
              return [...acc, companiesCostsNewList]
            }
            return [...acc]
          }, [])
      }
      return []
    },
    getTotal (): number|string {
      const total = this.companyListWithCosts.reduce(
        (acc: number, company: TrainingSessionCompanyCost) => {
          return acc + (company.cost || 0) + (company.additionalCost || 0)
        },
        0,
      )
      return total % 1 === 0
        ? total.toString()
        : total.toFixed(2).replace('.', ',')
    },
  },
})
