var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-material-card',{staticClass:"px-5 pt-3 mb-15",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","color":"primary","icon":"mdi-clipboard-text"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_vm._v(" Historique des modifications de la session de logistique ")])]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.listFiltered,"options":_vm.paginationOptions,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateHour")(item.createdAt))+" ")]}},{key:"item.userLastName",fn:function(ref){
var item = ref.item;
return [(item.userApplication.user)?[_vm._v(" "+_vm._s(item.userApplication.user.lastName)+" ")]:_vm._e(),(item.userApplication.trainer)?[_vm._v(" "+_vm._s(item.userApplication.trainer.lastName)+" ")]:_vm._e()]}},{key:"item.userFirstName",fn:function(ref){
var item = ref.item;
return [(item.userApplication.user)?[_vm._v(" "+_vm._s(item.userApplication.user.firstName)+" ")]:_vm._e(),(item.userApplication.trainer)?[_vm._v(" "+_vm._s(item.userApplication.trainer.firstName)+" ")]:_vm._e()]}},{key:"item.userGroups",fn:function(ref){
var item = ref.item;
return [(item.userApplication.user)?_vm._l((_vm.listUserGroups(item.userApplication.user.userGroups)),function(groupName,index){return _c('v-chip',{key:index,staticClass:"ma-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(groupName)+" ")])}):_vm._e(),(item.userApplication.trainer)?[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('trainer.form.is_region_manager'))+" ")])]:_vm._e()]}},{key:"item.section",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showSection(item.entityIri))+" ")]}},{key:"item.modifiedContent",fn:function(ref){
var item = ref.item;
return [(item.modifiedContent)?_vm._l((_vm.translateContent(item.modifiedContent)),function(field,index){return _c('v-chip',{key:index,staticClass:"ma-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(field)+" ")])}):_vm._e()]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }