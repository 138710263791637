









































import { defineComponent } from '@vue/composition-api'
import { RawLocation } from 'vue-router'
import { mapMutations } from 'vuex'
import { Vehicle } from '@/api/interfaces/vehicle'

export default defineComponent({
  name: 'VehiclesTableList',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    idLogisticSession: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      vehiclesTableHeaders: [
        { text: 'Référence', value: 'reference', align: 'center', sortable: false },
        { text: 'Marque', value: 'carBrand.brand', align: 'center', sortable: false },
        { text: 'Modèle', value: 'carModel.model', align: 'center', sortable: false },
        { text: 'Action', value: 'actions', align: 'center', sortable: false },
      ],
      vehicles: this.value,
    }
  },
  methods: {
    ...mapMutations('navigation', {
      forceCancelSaveNextRoute: 'forceCancelSaveNextRoute',
    }),
    associateVehicle () {
      const route: RawLocation = {
        name: 'Vehicle List Associate LogisticSession',
        params: {
          idLogisticSession: this.idLogisticSession,
        },
      }
      this.forceCancelSaveNextRoute(route)
      this.$router.push(route)
    },
    removeVehicle (vehicle: Vehicle) {
      (this.vehicles as Array<Vehicle>).splice(
        (this.vehicles as Array<Vehicle>).indexOf(vehicle),
        1,
      )
    },
  },

})
