import { render, staticRenderFns } from "./confirmModal.vue?vue&type=template&id=31cd5f58&scoped=true&"
import script from "./confirmModal.vue?vue&type=script&lang=ts&"
export * from "./confirmModal.vue?vue&type=script&lang=ts&"
import style0 from "./confirmModal.vue?vue&type=style&index=0&id=31cd5f58&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31cd5f58",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VDialog})
