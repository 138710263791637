import { TrainingSession } from '@/api/interfaces/trainingsession'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { Booking } from '@/api/interfaces/booking'
import { BookingStatus } from '@/api/enums/bookingStatus'

export function useTrainingSession () {
  function getNumberTraineesInSession (trainingSession: TrainingSession): number {
    const needTrainings = (trainingSession as TrainingSession).needTrainings as Array<NeedTraining>
    let bookings = (trainingSession as TrainingSession).bookings ?? [] as Array<Booking>

    bookings = bookings.filter(
      (booking: Booking) => {
        return booking.bookingStatus === BookingStatus.PENDING &&
          (
            !needTrainings.some((needTraining: NeedTraining) => needTraining.trainee.id === booking.needTraining?.trainee?.id)
          )
      },
    )

    return needTrainings.length + bookings.length
  }

  return {
    getNumberTraineesInSession,
  }
}
