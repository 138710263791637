

































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { TrainingLocation } from '@/api/interfaces/traininglocation'
import { QueryParams } from '@/store/api-plateform-utils'

export default defineComponent({
  name: 'SelectSearchTrainingLocation',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
    CreateTrainingLocation: () =>
    import('@/components/molecules/dialog/createTrainingLocation.vue'),
  },
  props: {
    value: {
      type:
        [(Array as () => TrainingLocation[]),
         (Object as () => TrainingLocation),
         String],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    labelSize: {
      type: String,
      required: false,
      default: 'long',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideDetails: {
      type: String,
      required: false,
      default: undefined,
    },
    canCreate: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      dialogTrainingLocation: false,
      searching: false,
      trainingLocationList: [] as Array<TrainingLocation>,
    }
  },
  computed: {
    ...mapState('trainingLocationList', {
      trainingLocationListStore: 'list',
    }),
  },
  watch: {
    value: {
      immediate: true,
      async handler () {
        if (!this.multiple && this.value && (this.value as TrainingLocation)['@id']) {
          const trainingLocation = (await this.loadByIri(
            (this.value as TrainingLocation)['@id'],
          )) as TrainingLocation
          this.trainingLocationList = [trainingLocation]
        }
      },
    },
    type: {
      async handler (newValue: string) {
        if (
          (this.value as TrainingLocation)?.name &&
          (this.value as TrainingLocation)?.type === newValue
        ) {
          const name = (this.value as TrainingLocation).name as string
          await this.loadTrainingLocations(name)
        } else {
          await this.loadTrainingLocations('')
        }
      },
    },
  },
  methods: {
    ...mapActions('trainingLocationList', {
      load: 'load',
    }),
    ...mapActions('trainingLocationForm', {
      loadByIri: 'loadByIri',
    }),
    itemText (item: TrainingLocation) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      let itemText = ''
      if (item?.name) {
        itemText += item.name
      }
      if (this.labelSize === 'long') {
        if (item?.type) {
          itemText += ' - ' + item.type
        }
        if (item?.address) {
          itemText += ' - ' + item.address
        }
      }
      if (item?.zipCode) {
        itemText += ' ' + item.zipCode
      }
      if (item?.city) {
        itemText += ' ' + item.city
      }
      if (this.labelSize === 'long') {
        if (item?.maximumCapacity) {
          const maximumCapacity = item.maximumCapacity + ' ' + this.$t('training_session.logger.places')
          itemText += ' - ' + maximumCapacity.toLowerCase()
        }
      }

      return itemText
    },
    async loadTrainingLocations (searchText: string) {
      if (searchText == null && this.value && (this.value as TrainingLocation)['@id']) {
        return
      }

      if (searchText !== '' && searchText === this.itemText((this.value as TrainingLocation))) {
        return
      }

      const request: QueryParams = {
        page: 1,
        itemsPerPage: 30,
      }

      if (searchText) {
        request.location = searchText
      }

      if (this.type) {
        request.type = this.type
      }

      this.searching = true
      this.trainingLocationList = await this.load(request)
      this.searching = false
    },
    onInput (uri: string) {
      if (this.multiple) {
        this.$emit('input', uri)
      } else {
        const trainingLocation = this.findTrainingLocationInList(uri)
        this.$emit('input', trainingLocation)
      }
    },
    toggleModal () {
      this.dialogTrainingLocation = !this.dialogTrainingLocation
    },
    findTrainingLocationInList (uri: string) {
      return (this.trainingLocationList as Array<TrainingLocation>).find(
        (x) => x['@id'] === uri,
      )
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    onClose () {
      if (this.canCreate) {
        this.dialogTrainingLocation = false
        if ((this.value as TrainingLocation)?.name) {
          const name = (this.value as TrainingLocation).name as string
          this.loadTrainingLocations(name)
        } else {
          this.loadTrainingLocations('')
        }
      }
    },
  },
})
