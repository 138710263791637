































































import { mapActions, mapState } from 'vuex'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { defineComponent, inject } from '@vue/composition-api'
import { trainingSessionDocument } from '@/api/interfaces/trainingSessionDocument'

export default defineComponent({
  setup () {
    const apiUrl = inject('getApiUrl')
    return {
      apiUrl,
    }
  },
  props: {
    trainingSession: {
      type: Object as () => TrainingSession,
    },
  },
  name: 'v-dialog-document-actions',
  data () {
    return {
      refreshTimeoutId: 0,
    }
  },
  computed: {
    ...mapState('DocumentGenerate', {
      percentage: 'percentage',
      idSessionDocuments: 'idSessionDocuments',
      idTrainingSessionDocumentsGenerated: 'idTrainingSession',
      mentionClimat: 'mentionClimat',
    }),
    lastTrainingSessionDocument (): trainingSessionDocument | null {
      const trainingSessionDocuments = (this.trainingSession as TrainingSession).trainingSessionDocuments
      if (trainingSessionDocuments && trainingSessionDocuments.length > 0) {
        return trainingSessionDocuments.reduce((prev: any, current: any) => {
          if (+current?.id > +prev?.id) {
            return current
          } else {
            return prev
          }
        },
        )
      }
      return null
    },
  },
  destroyed () {
    window.clearTimeout(this.refreshTimeoutId)
  },
  methods: {
    ...mapActions('DocumentGenerate', {
      launchGenerateDocument: 'launchGenerateDocument',
      getAdvancement: 'getAdvancement',
    }),
    startGenerateDocument () {
      this.launchGenerateDocument({
        idSession: (this.trainingSession as TrainingSession).id,
        mentionClimat: this.mentionClimat,
      }).then(r => {
        this.checkAdvancement(r)
        this.$emit('openAdvancementDialog', true)
      })
    },
    async checkAdvancement (idSessionDocuments: string) {
      await this.getAdvancement(idSessionDocuments)
      if (this.percentage === 100) {
        window.setTimeout(async () => {
          this.$emit('openAdvancementDialog', false)
        }, 2000)
        return
      }
      window.clearTimeout(this.refreshTimeoutId)
      this.refreshTimeoutId = window.setTimeout(async () => {
        await this.checkAdvancement(idSessionDocuments)
      }, 1000)
    },
  },
})
