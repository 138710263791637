






























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ConfirmModal',
  props: {
    textModal: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    setOpenValue (opened: boolean) : void {
      this.$emit('input', opened)
    },
    onClickValidate () : void {
      this.$emit('validate')
    },
  },
})
